<template>
  <div class="dashboard-widget-settings">

    <el-form
      label-position="left"
      label-width="200px"
      style="width: 500px"
      size="mini"
    >
      <el-form-item label="Тип графика">
        <el-radio-group v-model="localValue.type" class="chart-type-radio-group">
          <el-radio v-for="item in items" :key="item.value" :label="item.value" class="chart-type-radio">
            <img :src="item.imgSrc" alt="" />
            <div>{{ item.label }}</div>
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="Цвета">
        <el-color-picker
          v-for="color in localValue.colors"
          :key="color.value"
          v-model="color.value"
        ></el-color-picker>
      </el-form-item>

    </el-form>

    <component
      v-model="localValue"
      :is="chartSettingsTemplate"
    ></component>

  </div>
</template>

<script>
import Builder from "@/components/report/builder/Builder.vue";
import VQBForm from "@/components/report/builder/VQBForm.vue";
import {getChartWidgetTemplate} from "@/components/home/dashboard/widgets/VQBChartWidget/ChartWidgetHelper";

export default {
  name: 'VQBChartWidgetSettings',
  components: {VQBForm, Builder},
  props: {
    additionalTabs: {type: Array},
    value: { type: Object, required: true },
  },
  computed: {
    localValue: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    },
    chartSettingsTemplate() {
      return getChartWidgetTemplate(this.localValue.type, 'settings');
    },
    chartDataSettingsTemplate() {
      return getChartWidgetTemplate(this.localValue.type, 'data');
    },
  },
  watch: {
    'localValue.type': function(){
      this.registerTab();
    },
  },
  mounted() {
    this.registerTab();
  },
  data(){
    return {
      items: [
        { value: 'line', label: 'Line', imgSrc: require('@/assets/img/dashboard/charts/line.svg') },
        { value: 'area', label: 'Area', imgSrc: require('@/assets/img/dashboard/charts/area.svg') },
        { value: 'bar', label: 'Bar', imgSrc: require('@/assets/img/dashboard/charts/bar.svg') },
        { value: 'column', label: 'Column', imgSrc: require('@/assets/img/dashboard/charts/column.svg') },
        { value: 'pie', label: 'Pie', imgSrc: require('@/assets/img/dashboard/charts/pie.svg') },
      ],
    }
  },
  methods: {
    registerTab(){
      this.$emit('update:additionalTabs', [{
        label: 'Настройки данных',
        name: 'data',
        component: this.chartDataSettingsTemplate,
      }])
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";
.chart-type-radio-group {
  display: flex;

  .chart-type-radio{
    .el-radio__inner {
      display: none;
    }

    img{
      height: 100px;
    }

    div{
      margin-top: 10px;
      font-size: 16px;
      text-align: center;
    }
  }
  .chart-type-radio.is-checked {
    border: 4px solid $--color-primary;
    border-radius: $--border-radius-base;
  }
}




</style>